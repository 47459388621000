import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`Tiles are a highly flexible component for displaying a wide variety of content, including informational, getting started, how-to, next steps, and more.`}</p>
    <p>{`Carbon ships a basic tile structure that responds to the grid. Tiles have no pre-set styles for the content within them. You can customize tiles to fit your specific use case.`}</p>
    <p>{`When using a call-to-action (CTA) within a tile, use a `}<a parentName="p" {...{
        "href": "/components/button"
      }}>{`secondary button`}</a>{`. Primary buttons should be reserved for the most important action a user can take on the page.`}</p>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <p><em parentName="p">{`All of the images below represent examples of types of content that could be presented within a tile. The styling in these examples is for illustration only; you are free to create your own layout and design within a tile.`}</em></p>
    <h3 {...{
      "id": "read-only"
    }}>{`Read-only`}</h3>
    <p>{`Read-only tiles are used to display information to the user, such as features or services offered. Read-only tiles are often seen on marketing pages to promote content. These tiles can have internal calls-to-action (CTAs), such as a `}<a parentName="p" {...{
        "href": "/components/button"
      }}>{`button`}</a>{` or a `}<a parentName="p" {...{
        "href": "/components/link"
      }}>{`link`}</a>{`.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.76785714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAACEklEQVQoz2NggAKtkv0M5ID///+jCvCYH2LQLj7PCGJLBp3wkfVc36yeeqJSKWJXtUrormoR371Vee37oxcvnVNd1djWEJ9VUl9QVlHbM3FS2679B0JA+k6fP8Owcv1GiIEK0acZZJMuMYHY8onnV6nH3fuvmnvvv1rslf8acdf/S8Vc/1878/zxZUtn/49ISPsvr2H6397d7//6rVv/X7h6bTtI3+t3rxh3HzwEMVAy5SKDZtZVsIGKGVfnyKde+q+UceqbXPzxX8LBJ38JBh//pefVuc3CweWjpon9HwZejV96TpHfI9JL/3tEZC4D6cuq7GB0DEqCGNi75ytDx6qzYAO7tjyeP3nbs/8Ttt7/3bP67r/+tQ/+dyy686+2c8fOkvrOb2VVTf+7piz419A+8Xdz7+z/bVNWrALp65y2irGxbyE0UH9/Yrh05jjYwLfPH8x58/zB/5dP7n17//rJrz8/P/76///7z///fmx7+vjhx9evX/55/+7Nr9+/fn7/DwHLoBHDCI+cDx8/MTx88gJs4MvX71aDVH37/uM/Mnj37sOJ9x8+/f/1+8//f//+A/E/sPiHDx/AYfjhw0fG9x8+QAz89Okzw93nP8Cx/PLdV78nr7+1fvn6rfrLl8+1nz+D8KfaDx8/Rz969a3y/advdV+/fgGJ13z69KkFiIMhZnwCYxRw/+UvstIhukEAczpIzrXTvFAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example image of read-only tiles.",
            "title": "Example image of read-only tiles.",
            "src": "/static/7e413c28826c8dfc4ed872f1a2f03167/fb070/tile-usage-1.png",
            "srcSet": ["/static/7e413c28826c8dfc4ed872f1a2f03167/d6747/tile-usage-1.png 288w", "/static/7e413c28826c8dfc4ed872f1a2f03167/09548/tile-usage-1.png 576w", "/static/7e413c28826c8dfc4ed872f1a2f03167/fb070/tile-usage-1.png 1152w", "/static/7e413c28826c8dfc4ed872f1a2f03167/fb104/tile-usage-1.png 1728w", "/static/7e413c28826c8dfc4ed872f1a2f03167/8fefe/tile-usage-1.png 2240w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "clickable"
    }}>{`Clickable`}</h3>
    <p>{`Clickable tiles can be used as navigational items, where the entire tile is a clickable state, which redirects the user to a new page. Clickable tiles cannot contain separate internal CTAs.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.035714285714285%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAA20lEQVQY052RSw6CQAyGITFuPRR34BYsdCGIuNA7GI9jvAZbwivhPSCPGOpfAhjDjibNzPxtv7YZSRotiiJZWmlFUfweQogZliSJEobh1XXdI5IMxPSyLK08z9UgCPbQTeSybkA/49QQ206cGRjH8YbvCN4JZts2+b5Pnufxk1D8RKMPgOQ4DvFZ1zXrIk3T3QIIH4BVVT0YgCkIiYQGE/CFHGqaZtDZ+76nLMveXdctgVhPHidUUHxr21aHnwDg1S6Iq/ADpjJZh/PaFjbT0O9/5ckAW/0pgM73LwJZ8CrJhl+JAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example image of clickable tiles.",
            "title": "Example image of clickable tiles.",
            "src": "/static/3f0c8594d4bda30fcb1919fa4bcd079b/fb070/tile-usage-2.png",
            "srcSet": ["/static/3f0c8594d4bda30fcb1919fa4bcd079b/d6747/tile-usage-2.png 288w", "/static/3f0c8594d4bda30fcb1919fa4bcd079b/09548/tile-usage-2.png 576w", "/static/3f0c8594d4bda30fcb1919fa4bcd079b/fb070/tile-usage-2.png 1152w", "/static/3f0c8594d4bda30fcb1919fa4bcd079b/fb104/tile-usage-2.png 1728w", "/static/3f0c8594d4bda30fcb1919fa4bcd079b/8fefe/tile-usage-2.png 2240w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "selectable"
    }}>{`Selectable`}</h3>
    <p>{`Selectable tiles work like a `}<a parentName="p" {...{
        "href": "/components/radio-button"
      }}>{`radio button`}</a>{`, where the entire tile is a click target. Selectable tiles may contain internal CTAs (like links to docs) if the internal CTA is given its own click target. Selectable tiles work well for presenting options to a user in a structured manner, such as a set of pricing plans.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.035714285714285%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAA5UlEQVQY061PS4rCUBB89/AqzjkEN7ryBk5eJHg4mYW4l0kgZJX//x8SkrTVYUZ0b0PxuvtVdXUL8enwPG9r2/a5KAolz3NZVZVa17WC/kbX9aPjOKrv+zKOY+m6rmYYxg5/B87LspTQLRrkCt6VsCzrmiQJQURpmtI4jjTPM9cXiCkIAoqiiGBCHKjvpmnaYRgSI8uyhc/A0LXAsBsTu66j18CQHxBoGAaapunZh+kvtnU57/v+TQP+l2iaZtu27RngldU/KHDfQPiNDU4wVWEgAQ2n7rHtAXwNWj73/2S5nPzpeADCGhfXjQ5jxgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example image of selectable tiles.",
            "title": "Example image of selectable tiles.",
            "src": "/static/881a6caf84b59f30f41586d94198713e/fb070/tile-usage-3.png",
            "srcSet": ["/static/881a6caf84b59f30f41586d94198713e/d6747/tile-usage-3.png 288w", "/static/881a6caf84b59f30f41586d94198713e/09548/tile-usage-3.png 576w", "/static/881a6caf84b59f30f41586d94198713e/fb070/tile-usage-3.png 1152w", "/static/881a6caf84b59f30f41586d94198713e/fb104/tile-usage-3.png 1728w", "/static/881a6caf84b59f30f41586d94198713e/8fefe/tile-usage-3.png 2240w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "expandable"
    }}>{`Expandable`}</h3>
    <p>{`Expandable tiles are helpful for hiding/showing larger amounts of content to a user. They can only be stacked in a single column, and cannot live in a row or horizontal grid. When expanded, tiles push content down the page. Expandable tiles may contain internal CTAs (like links to docs) if the internal CTA is given its own click target.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.14285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABnUlEQVQoz6WS2UrDUBCGk97mBcQLUQTBivisSvoqhfaiEH2JtpTuW5LuW2IWj98cktgKXhn488/Myfyz5Bjr9do0eDabzbXv+2/tdrvSbDbtTqdjt1ot23Vdm2/s3W5n7/f7gjO7Ar+CK9GATWO73ZbE4fB5tVp9DgYDBdLZbJYili4WCw3OUxI058BXcIjGY6ZhSme6Q4J3y+Wyili91+vVBNiaEayRXCOhgPigjl0FN+eCeYcvUpHxLiCxOI5VmqYqSZICeSwIAvnmqRiZVylznkHY7/fT6XQaUyjBTkajkWYBa0g8z0tYhXBMQRk7oJmfkXkVHR4OBzWfz9VwOFSsQE0mE22Px2MNxBXFFLtWp9NJhWEo3X2B8nmHZuaUEZwi6FPdxfaOx6NHXIOCHkU0spgLfOITYg/ZfzCN/Nrg3LP8Bj/ivdvtOoyogbjD+A6JDgIaYmf+B9wAt8XIv65NIHtirAiOZZfsK5Z9cR4joDlDhJ/Ap4trE0WR2AY7KSmlrL/ACiwSLEQ0cpurZlFUT4lt5GLGfx+m0PwN+ahwA9dwR2sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example image of expandable tiles.",
            "title": "Example image of expandable tiles.",
            "src": "/static/0026696e75f036f705db74f60c075e43/fb070/tile-usage-4.png",
            "srcSet": ["/static/0026696e75f036f705db74f60c075e43/d6747/tile-usage-4.png 288w", "/static/0026696e75f036f705db74f60c075e43/09548/tile-usage-4.png 576w", "/static/0026696e75f036f705db74f60c075e43/fb070/tile-usage-4.png 1152w", "/static/0026696e75f036f705db74f60c075e43/fb104/tile-usage-4.png 1728w", "/static/0026696e75f036f705db74f60c075e43/8fefe/tile-usage-4.png 2240w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      